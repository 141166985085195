<template>
  <div>
    <Spinner v-if="isLoading" />
    <h3
      v-if="isLoading"
      style="width:100%;text-align:center;"
    >Please wait until the ticket trend data is resolved...</h3>

    <v-container
      v-if="!isLoading"
      fill-height
      fluid
      grid-list-xl
      style="margin: 5px 0 20px 0;"
    >
      <v-layout wrap>
        <v-flex
          :class="getWidgetClass(3)">
          <BasicWidgetComponent
            :widget-id="widgetP1toP4Closed.id"
            :value="widgetP1toP4Closed.data? widgetP1toP4Closed.data[0].closed_Tickets : 0"
            :title="widgetP1toP4Closed.title"
            :polling-interval="widgetP1toP4Closed.pollingInterval"
            :suffix="' Tickets'" />
        </v-flex>
        <v-flex
          :class="getWidgetClass(3)">
          <BasicWidgetComponent
            :widget-id="widgetMIs.id"
            :value="widgetMIs.data? widgetMIs.data[0].number_of_MI_Tickets : 0"
            :title="widgetMIs.title"
            :polling-interval="widgetMIs.pollingInterval"
            :suffix="' Tickets'" />
        </v-flex>
        <v-flex
          :class="getWidgetClass(3)">
          <BasicWidgetComponent
            :widget-id="widgetSLASummary.id"
            :value="widgetSLASummary.data? widgetSLASummary.data[0].noInsideSLA : 0"
            :title="widgetSLASummary.title"
            :polling-interval="widgetSLASummary.pollingInterval"
            :suffix="' Tickets Inside SLA'" />
        </v-flex>
        <v-flex
          :class="getWidgetClass(3)">
          <BasicWidgetComponent
            :widget-id="widgetSLASummary.id"
            :value="widgetSLASummary.data? widgetSLASummary.data[0].noOutsideSLA : 0"
            :title="widgetSLASummary.title"
            :polling-interval="widgetSLASummary.pollingInterval"
            :suffix="' Tickets Outside SLA'" />
        </v-flex>
        <v-flex
          :class="getWidgetClass(4)"
        >
          <ChartSLAGauge
            :widget="widgetSLAGaugeP1"
          />
        </v-flex>
        <v-flex
          :class="getWidgetClass(4)"
        >
          <ChartSLAGauge
            :widget="widgetSLAGaugeP2"
          />
        </v-flex>
        <v-flex
          :class="getWidgetClass(4)"
        >
          <ChartSLAGauge
            :widget="widgetSLAGaugeP3"
          />
        </v-flex>
        <v-flex
          :class="getWidgetClass(6)"
        >
          <ChartTicketsRaised
            :widget="widgetP1toP4Raised"
          />
        </v-flex>
        <v-flex
          :class="getWidgetClass(6)">
          <ChartContractTimeUsage
            :widget="widgetContractTimeUsage"
          />
        </v-flex>
        <v-flex
          v-if="widgetTicketsPerSite !== ''"
          :class="getWidgetClass(getSiteListDisplayClass())">
          <ChartTicketsPerSite
            :widget="widgetTicketsPerSite"
          />
        </v-flex>
        <v-flex
          :class="getWidgetClass(6)">
          <ChartSurveyScores
            :widget="widgetSurveyScores"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <br><br>
  </div>
</template>

<script>
import TextButton from '../components/common/textButton'
import ChartSurveyScores from '../components/ticketTrends/chartSurveyScores'
import ChartMACsUsed from '../components/ticketTrends/chartMACsUsed'
import ChartSLAGauge from '../components/ticketTrends/chartSLAGauge'
import ChartTicketsRaised from '../components/ticketTrends/chartTicketsRaised'
import ChartContractTimeUsage from '../components/ticketTrends/chartContractTimeUsage'
import ChartTicketsPerSite from '../components/ticketTrends/chartTicketsPerSite'
import BasicWidgetComponent from '../components/ticketTrends/basicWidgetComponent'
import { reportsMixin } from '../mixins/reportsMixin'
import Spinner from '../components/common/spinner'
import { DataEventBus } from '../events/dataEvents'
export default {
  metaInfo: {
    title: 'iQuote - Ticket Trends'
  },
  components: {
    TextButton,
    Spinner,
    ChartSurveyScores,
    ChartMACsUsed,
    ChartContractTimeUsage,
    ChartSLAGauge,
    ChartTicketsRaised,
    ChartTicketsPerSite,
    BasicWidgetComponent
  },
  mixins: [
    reportsMixin
  ],
  data () {
    return {
      isLoading: false,
      widgetP1toP4Raised: {},
      widgetP1toP4Closed: {},
      widgetMIs: {},
      widgetSLAGaugeP1: {},
      widgetSLAGaugeP2: {},
      widgetSLAGaugeP3: {},
      widgetTicketsPerSite: {},
      widgetSurveyScores: {},
      widgetSLASummary: {},
      widgetContractTimeUsage: {},
      ticketSiteData: {}
    }
  },
  computed: {
  },
  mounted: async function () {
    DataEventBus.$on('refresh-data', () => {
      var that = this
      setTimeout(function () {
        that.getWidgets()
      }, 3000)
    })
    await this.getWidgets()
  },
  methods: {
    getWidgets: async function () {
      this.isLoading = true

      // Note: The two lines below set the Tickets Per Site graph to a full-screen display if there are more than ten sites
      //       with tickets. This is - for the moment - entirely redundant, since the data setup for the graph takes the
      //       top ten sites only. The code is retained here for future-proofing in case they change their minds.
      this.ticketSiteData.data = await this.getTicketsPerSite()
      this.ticketSiteData.displayClass = (this.ticketSiteData.data.length > this.threshholdTicketsForFullScreenWidget) ? 12 : 6

      this.widgetP1toP4Raised.data = await this.getP1toP4Raised()
      this.widgetP1toP4Raised.id = 1
      this.widgetP1toP4Raised.type = 'bar'
      this.widgetP1toP4Raised.title = this.ticketPriorityTitle + ' Tickets Raised In Last 30 Days'
      this.widgetP1toP4Raised.pollingInterval = 300

      this.widgetP1toP4Closed.data = await this.getP1toP4Closed()
      this.widgetP1toP4Closed.id = 2
      this.widgetP1toP4Closed.title = this.ticketPriorityTitle + ' Tickets Closed In Last 30 Days'
      this.widgetP1toP4Closed.pollingInterval = 300

      this.widgetMIs.data = await this.getMIs()
      this.widgetMIs.id = 3
      this.widgetMIs.title = 'Major Incidents In Last 30 Days'
      this.widgetMIs.pollingInterval = 300

      this.widgetTicketsPerSite.data = this.ticketSiteData.data
      this.widgetTicketsPerSite.id = 4
      this.widgetTicketsPerSite.type = 'bar'
      this.widgetTicketsPerSite.title = 'Tickets Per Site Over The Last 30 Days (Top Ten Sites)'
      this.widgetTicketsPerSite.pollingInterval = 300

      this.widgetSLASummary.data = await this.getSLASummary()
      this.widgetSLASummary.id = 6
      this.widgetSLASummary.title = 'Ticket SLA Summary'
      this.widgetSLASummary.pollingInterval = 300

      this.widgetSurveyScores.data = await this.getSurveyResultMonthlyComparison()
      this.widgetSurveyScores.id = 7
      this.widgetSurveyScores.type = 'line'
      this.widgetSurveyScores.title = 'Survey Scores'
      this.widgetSurveyScores.pollingInterval = 300
      console.log(this.widgetSurveyScores)

      this.widgetContractTimeUsage.data = await this.getContractTimeUsage()
      this.widgetContractTimeUsage.id = 8
      this.widgetContractTimeUsage.type = 'bar'
      this.widgetContractTimeUsage.title = 'MAC Usage'
      this.widgetContractTimeUsage.pollingInterval = 300

      this.widgetSLAGaugeP1.data = await this.getSLAGauge('P1')
      this.widgetSLAGaugeP1.id = 9
      this.widgetSLAGaugeP1.type = 'radialBar'
      this.widgetSLAGaugeP1.title = 'Tickets in SLA - P1'
      this.widgetSLAGaugeP1.pollingInterval = 300

      this.widgetSLAGaugeP2.data = await this.getSLAGauge('P2')
      this.widgetSLAGaugeP2.id = 10
      this.widgetSLAGaugeP2.type = 'radialBar'
      this.widgetSLAGaugeP2.title = 'Tickets in SLA - P2'
      this.widgetSLAGaugeP2.pollingInterval = 300

      this.widgetSLAGaugeP3.data = await this.getSLAGauge('P3')
      this.widgetSLAGaugeP3.id = 11
      this.widgetSLAGaugeP3.type = 'radialBar'
      this.widgetSLAGaugeP3.title = 'Tickets in SLA - P3'
      this.widgetSLAGaugeP3.pollingInterval = 300

      this.isLoading = false
    },
    getWidgetClass (width) {
      switch (width) {
        case 3:
          return 'xl3 lg3 md4 sm12'
        case 4:
          return 'xl4 lg4 md6 sm12'
        case 6:
          return 'xl6 lg6 md12 sm12'
        default:
          return 'xl12 lg12 md12 sm12'
      }
    },
    getSiteListDisplayClass () {
      return this.ticketSiteData.displayClass
    }
  }
}
</script>
<style scoped>
  .container {
    padding: 24px 24px 0 24px;
  }
  .ticketsPerSite {
    flex: 0 0 100%;
  }
</style>
